@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/theme/theme.css");

@layer components {
  .wapper_small {
    @apply max-w-screen-lg m-auto;
  }

  .__content {
    @apply p-4 bg-white rounded shadow;
  }

  .footer_modal {
    padding-top: 10px;
    @apply flex justify-end gap-4 border-t;
  }

  .shadow_antd {
    @apply bg-white cursor-pointer rounded p-2 space-y-2;
    box-shadow:
      0 3px 6px -4px #0000001f,
      0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
  }

  .show__sider {
    flex: 0 0 260px;
    max-width: 260px;
    min-width: 260px;
    width: 260px;
  }

  .hide__sider {
    flex: 0 0 88px;
    max-width: 88px;
    min-width: 88px;
    width: 88px;
  }

  .text-filter {
    @apply text-dark-400 mb-[4px] text-base;
  }

  .menu__item {
    @apply rounded h-11 px-4 flex items-center gap-4 ease-in-out duration-200;
  }
}

.w-btn {
  min-width: 128px;
}

.h-btn {
  height: 48px !important;
}

/* tabs__custom */
.tabs__custom .ant-tabs-nav {
  margin: 0px !important;
}

.tabs__custom .ant-tabs-nav::before {
  border-bottom: none !important;
}

.tabs__custom .ant-tabs-tab {
  background-color: var(--color-primary-50) !important;
  margin-left: 0px !important;
  border: none !important;
  border-radius: 12px 12px 0px 0px !important;
  box-shadow:
    0px 9px 28px 8px #00000005,
    0px 3px 6px -4px #0000000f,
    0px 6px 16px 0px #0000000a;
}

.tabs__custom .ant-tabs-tab .ant-tabs-tab-btn {
  @apply text-primary-300;
}

.tabs__custom .ant-tabs-tab-active {
  background-color: white !important;
  box-shadow: 4px 6px 16px 0px #0000000a;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.tabs__custom .ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply text-primary-700;
}

/* tabs__custom__secondary*/
.tabs__custom__secondary .ant-tabs-nav {
  margin: 0px !important;
}

.tabs__custom__secondary .ant-tabs-nav::before {
  border-bottom: none !important;
}

.tabs__custom__secondary .ant-tabs-tab {
  background-color: white !important;
  margin-left: 0px !important;
  border: none !important;
  border-radius: none;
  box-shadow: none;
}

.tabs__custom__secondary .ant-tabs-tab .ant-tabs-tab-btn {
  color: #646970;
  padding: 0 12px;
  font-size: 16px;
}

.tabs__custom__secondary .ant-tabs-tab.ant-tabs-tab {
  border-bottom: 2px solid #e6edf2 !important;
  z-index: 2;
}

.tabs__custom__secondary .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid var(--color-primary-600) !important;
  z-index: 2;
}

.tabs__custom__secondary .ant-tabs-tab-active {
  box-shadow: none;
  filter: none;
}

.tabs__custom__secondary .ant-tabs-tab-active .ant-tabs-tab-btn {
  padding: 0 12px;
  color: var(--color-primary-600) !important;
}

/* __approval_seting_form */
.__approval_seting_form .ant-select-selection-item {
  background-color: var(--color-primary-100) !important;
}

.__approval_seting_form .ant-select-selection-item .ant-select-selection-item-remove {
  color: var(--color-primary-700) !important;
}

/* custom collapse_month_result */
.collapse_month_result {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header,
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 16px !important;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 40px !important;
  }

  .ant-collapse {
    margin-top: 6px !important;
  }
}

/* checkbox_custom size */
.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}

/*custom radioButton*/

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: white !important;
  border-color: var(--color-primary-600) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-primary-600);
  /* transform: scale(0.577) !important; */
}

/*custom button primary*/
.ant-btn-primary {
  box-shadow: none !important;
}

.custom-video video {
  object-fit: cover;
}

.custom-tab .ant-tabs-tab {
  padding: 10px !important;
}

.custom-pagination .ant-pagination-item-ellipsis {
  color: black !important;
  letter-spacing: normal !important;
  font-size: 7px;
  margin-top: 3px !important;
}

.full-view {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: white;
  z-index: 100;
}

.transition-1 {
  transition: 0.5s;
}

.highlight-row .ant-table-cell {
  background-color: #f5f5f5 !important; /* Màu highlight */
}

.ant-table-row:hover,
.ant-table-row-selected {
  background-color: #f5f5f5 !important;
}

.ant-table-cell-fixed-right.ant-table-cell-fix-right-first {
  background-color: inherit !important;
}

@media print {
  @page {
    /* size: A4 !important; */
    margin: 0 !important;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .print-container {
    margin: 0 !important;
    padding: 0 !important;
    background-color: black;
    color: white;
  }

  #contentToPrint {
    z-index: 99999999999 !important;
    position: absolute;
  }
}

.datepicker-form .anticon-calendar {
  color: #646970 !important;
}

.billing-modal .ant-modal-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* .ant-table-body {
  overflow-y: hidden !important;
} */
